<template>
  <ContextMenu closeOnOutsideClick @onClosed="performAction">
    <template v-if="options.length > 0" #facing="props">
      <div ref="opener">
        <slot v-bind="props" />
      </div>
    </template>
    <template #menu="{ close, position }">
      <ul
        class="min-w-max divide-y divide-neutral-300/50 dark:divide-neutral-700/50"
      >
        <li
          v-for="(option, index) in options"
          class="relative cursor-pointer"
          :class="{
            'opacity-50 pointer-events-none': option.disabled,
          }"
          ref="optionItems"
        >
          <component
            class="flex items-center px-4 py-2 select-none transition-colors duration-75"
            :class="{
              'hover:text-nami-nami-blue hover:underline': option.href,
              'hover:bg-neutral-200/50 hover:dark:bg-neutral-600/50':
                !option.href,
            }"
            :is="option.href ? TheNuxtLink : 'span'"
            :to="option.href"
            :target="option.href?.includes('http') ? '_blank' : ''"
            @pointerup="
              () => {
                selectedOptionIndex = index;
                close();
              }
            "
          >
            <component
              v-if="option.icon"
              :is="option.icon"
              class="mr-2"
              :size="20"
            />
            <span>
              {{ option.name }}
            </span>
          </component>
        </li>
      </ul>
    </template>
  </ContextMenu>
</template>

<script setup lang="ts">
import { IconPlaceholder } from "@tabler/icons-vue";
import ContextMenu, {
  type Props as ContextMenuProps,
} from "~/components/contextMenu/ContextMenu.vue";

export interface ContextMenuOption {
  name: string;
  href?: string;
  action?: () => void;
  disabled?: boolean;
  icon?: typeof IconPlaceholder;
  confirm?: boolean;
}

interface Props extends ContextMenuProps {
  options: ContextMenuOption[];
}

const props = defineProps<Props>();
const TheNuxtLink = resolveComponent("TheNuxtLink");
const selectedOptionIndex = ref(-1);

const opener = ref<HTMLDivElement>();

function performAction() {
  if (selectedOptionIndex.value === -1) {
    return;
  }

  props.options[selectedOptionIndex.value].action?.call(null);
  selectedOptionIndex.value = -1;
}
</script>
